import { createAction } from "@reduxjs/toolkit";

import {
  createSlice,
  initialAppState,
} from "@marta/core/features/app/slice/app-reducer";
import { AppLocale } from "@marta/core/features/locale";
import { getActionDispatcherWithPayload } from "@marta/utils";

import { AppState } from "./app-types";

/**
 * You can extends this slice
 * providing new options as parameter.
 *
 * example:
 *
 * First you need to extend the `AppState` in './app-types.ts'.
 *
 * import { createAction } from "@reduxjs/toolkit";
 * const incrementBy = createAction<number>("incrementBy");
 *
 * const slice = createSlice<AppState>({
 *   initialState: {
 *     count: 0
 *   } as AppState,
 *   reducers: (builder) => {
 *     builder.addCase(incrementBy, (state, action) => {
 *       state.count += action.payload;
 *     })
 *   }
 * });
 */

/**
 * ------------------------------
 * Notification request state dispatcher
 * ------------------------------
 */
export type NotificationRequestStateType = AppState["notificationRequestState"];

export const setFirstPostingNotificationRequested = createAction<
  NotificationRequestStateType["firstPosting"]
>("setFirstPostingNotificationRequested");

export const useSetFirstApplicationNotificationRequested =
  getActionDispatcherWithPayload(setFirstPostingNotificationRequested);

export const setInMatchingNotificationRequested = createAction<
  NotificationRequestStateType["inMatching"]
>("setInMatchingNotificationRequested");

export const useSetInMatchingNotificationRequested =
  getActionDispatcherWithPayload(setInMatchingNotificationRequested);

/**
 * ------------------------------
 * Entities state dispatcher
 * ------------------------------
 */

export const setFamily =
  createAction<AppState["cache"]["entities"]["family"]>("setFamily");

export const useSetReduxFamily = getActionDispatcherWithPayload(setFamily);

export const setElders =
  createAction<AppState["cache"]["entities"]["elders"]>("setElders");

export const useSetReduxElders = getActionDispatcherWithPayload(setElders);

export const setClient =
  createAction<AppState["cache"]["entities"]["client"]>("setClient");

export const useSetReduxClient = getActionDispatcherWithPayload(setClient);

export const setHouses =
  createAction<AppState["cache"]["entities"]["houses"]>("setHouses");

export const useSetReduxHouses = getActionDispatcherWithPayload(setHouses);

/**
 * ------------------------------
 * Form fields state dispatcher
 * ------------------------------
 */

export const setCgFormFields =
  createAction<AppState["cache"]["formFields"]["caregiver"]>("setCgFormFields");

export const useSetReduxCgFormFields =
  getActionDispatcherWithPayload(setCgFormFields);

export const setFamilyFormFields = createAction<
  AppState["cache"]["formFields"]["family"]
>("setFamilyFormFields");

export const useSetReduxFamFormFields =
  getActionDispatcherWithPayload(setFamilyFormFields);

/**
 * ------------------------------
 * Favorite Caregivers state dispatcher
 * ------------------------------
 */

export const setFavoriteCaregivers = createAction<
  AppState["cache"]["favoriteCaregivers"]
>("setFavoriteCaregivers");

export const setFavoritesPromoShown = createAction<
  AppState["favoritesPromoShown"]
>("toggleFavoritesPromoShown");

export const toggleFavorite =
  createAction<AppState["favoritesEnabled"]>("toggleFavorite");

export const addFavorite =
  createAction<AppState["cache"]["favoriteCaregivers"]>("addFavorite");

export const removeFavorite =
  createAction<AppState["cache"]["favoriteCaregivers"]>("removeFavorite");

export const showRateCGReminder =
  createAction<AppState["rateCGReminder"]>("showRateCGReminder");

export const useSetReduxFavoriteCaregivers = getActionDispatcherWithPayload(
  setFavoriteCaregivers,
);

export const useToggleReduxFavoriteCaregivers =
  getActionDispatcherWithPayload(toggleFavorite);

export const useAddReduxFavoriteCaregivers =
  getActionDispatcherWithPayload(addFavorite);

export const useRemoveReduxFavoriteCaregivers =
  getActionDispatcherWithPayload(removeFavorite);

export const useSetReduxFavoritesPromoShown = getActionDispatcherWithPayload(
  setFavoritesPromoShown,
);

export const useSetReduxRemindRatingCG =
  getActionDispatcherWithPayload(showRateCGReminder);

// these values will be merged
// with the base app state initial values
// inside `createSlice` function below
export const initialState: AppState = {
  ...initialAppState,
  locale: AppLocale.De,
  modalState: {
    notification: "initial",
  },
  notificationRequestState: {
    firstPosting: false,
    inMatching: false,
  },
  cache: {
    entities: {},
    formFields: {},
    favoriteCaregivers: [],
  },
  favoritesPromoShown: undefined,
  favoritesEnabled: false,
  rateCGReminder: true,
};

const { reducer, actions } = createSlice<AppState>({
  initialState,
  reducers(builder) {
    const initializeNotificationRequestState = (state: AppState) => {
      if (!state.notificationRequestState) {
        // if the state is not present, we'll initialize it
        state.notificationRequestState = {
          firstPosting: false,
          inMatching: false,
        };
      }
    };

    const initializeEntitiesState = (state: AppState) => {
      if (!state.cache) {
        state.cache = {
          entities: {},
          formFields: {},
          favoriteCaregivers: [],
        };
      }

      if (!state.cache.entities) {
        // if the state is not present, we'll initialize it
        state.cache.entities = {};
      }
    };

    const initializeFormFieldsState = (state: AppState) => {
      if (!state.cache) {
        state.cache = {
          entities: {},
          formFields: {},
          favoriteCaregivers: [],
        };
      }

      if (!state.cache.formFields) {
        // if the state is not present, we'll initialize it
        state.cache.formFields = {};
      }
    };

    const initializeFavoriteCaregiversState = (state: AppState) => {
      if (!state.cache) {
        state.cache = {
          entities: {},
          formFields: {},
          favoriteCaregivers: [],
        };
      }

      if (!state.cache.favoriteCaregivers) {
        // if the state is not present, we'll initialize it
        state.cache.favoriteCaregivers = [];
      }
    };

    const initializeFavoritesEnabledState = (state: AppState) => {
      if (!state.favoritesEnabled) {
        state.favoritesEnabled = false;
      }
    };

    const initializeFavoritesPromoShownState = (state: AppState) => {
      if (!state.favoritesPromoShown) {
        state.favoritesPromoShown = undefined;
      }
    };

    const initializeShowRateCGReminder = (state: AppState) => {
      if (!state.rateCGReminder) {
        state.rateCGReminder = false;
      }
    };

    builder.addCase(setFirstPostingNotificationRequested, (state, action) => {
      initializeNotificationRequestState(state);
      state.notificationRequestState.firstPosting = action.payload;
    });

    builder.addCase(setInMatchingNotificationRequested, (state, action) => {
      initializeNotificationRequestState(state);
      state.notificationRequestState.inMatching = action.payload;
    });

    builder.addCase(setFamily, (state, action) => {
      initializeEntitiesState(state);
      state.cache.entities.family = action.payload;
    });

    builder.addCase(setElders, (state, action) => {
      initializeEntitiesState(state);
      state.cache.entities.elders = action.payload;
    });

    builder.addCase(setClient, (state, action) => {
      initializeEntitiesState(state);
      state.cache.entities.client = action.payload;
    });

    builder.addCase(setHouses, (state, action) => {
      initializeEntitiesState(state);
      state.cache.entities.houses = action.payload;
    });

    builder.addCase(setCgFormFields, (state, action) => {
      initializeFormFieldsState(state);
      state.cache.formFields.caregiver = action.payload;
    });

    builder.addCase(setFamilyFormFields, (state, action) => {
      initializeFormFieldsState(state);
      state.cache.formFields.family = action.payload;
    });

    builder.addCase(setFavoriteCaregivers, (state, action) => {
      initializeFavoriteCaregiversState(state);
      state.cache.favoriteCaregivers = action.payload;
    });

    builder.addCase(toggleFavorite, (state, action) => {
      initializeFavoritesEnabledState(state);
      state.favoritesEnabled = action.payload;
    });

    builder.addCase(setFavoritesPromoShown, (state, action) => {
      initializeFavoritesPromoShownState(state);
      state.favoritesPromoShown = action.payload;
    });

    builder.addCase(addFavorite, (state, action) => {
      state.cache.favoriteCaregivers.push(action.payload as never);
    });

    builder.addCase(removeFavorite, (state, action) => {
      state.cache.favoriteCaregivers = state.cache.favoriteCaregivers.filter(
        (id) => id !== (action.payload as never),
      );
    });

    builder.addCase(showRateCGReminder, (state, action) => {
      initializeShowRateCGReminder(state);
      state.rateCGReminder = action.payload;
    });
  },
});

export const appReducer = reducer;
/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { incrementBy } = useAppSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(incrementBy(3));
 *   };
 * }
 */
export const useAppActions = () => {
  // append any `createAction` result here
  return {
    ...actions,
    //incrementBy
  };
};
