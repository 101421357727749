import { createSlice } from "@reduxjs/toolkit";

import { CaregiverState } from "./caregiver-types";

export const initialState = { filters: [] } as CaregiverState;

const caregiverSlice = createSlice({
  name: "caregiver",
  initialState,
  reducers: {
    updateFilters(state, action) {
      state.filters = action.payload;
    },
    clearFilters(state) {
      state.filters = [];
    },
  },
});

export const caregiverReducer = caregiverSlice.reducer;

export const useCaregiverActions = () => {
  return caregiverSlice.actions;
};
