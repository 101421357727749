import localhost from "../localhost";
import { FamilyAppEnvVariables } from "../types";

import googleClientId from "./googleClientId";

const env: FamilyAppEnvVariables = {
  api: {
    //
    url: `${localhost}:4002`,
    origin: `${localhost}:3006`,
  },
  services: {
    //
    translations: "http://localhost:9003/translation-service/api",
    search: "http://localhost:9004/search-service/api",
    auth: `${localhost}:9001/auth-service/api`,
    communication: `${localhost}:9002/communication-service/api`,
    document: `${localhost}:9005/document-service/api`,
    lead: `${localhost}:9007/lead-service/api`,
  },
  apple: {
    //
    clientId: "com.marta.family.dev",
  },
  google: {
    //
    clientId: googleClientId,
  },
  rudderstack: {
    //
    writeKey: "2R6M9S2DKN5v5zoy7NnJ0A6Etzi",
    dataPlaneUrl: "https://martaanalhqwm.dataplane.rudderstack.com",
  },
};

export default env;
