import { FamilyAppEnvVariables } from "../types";

import googleClientId from "./googleClientId";

const env: FamilyAppEnvVariables = {
  api: {
    //
    url: "https://api.appmarta.com",
    origin: "https://app.appmarta.com",
  },
  services: {
    //
    translations: "https://svc.appmarta.com/translation-service/api",
    search: "https://svc.appmarta.com/search-service/api",
    auth: "https://svc.appmarta.com/auth-service/api",
    communication: "https://svc.appmarta.com/communication-service/api",
    document: "https://svc.appmarta.com/document-service/api",
    lead: "https://svc.appmarta.com/lead-service/api",
  },
  apple: {
    //
    clientId: "com.marta.family.web",
  },
  google: {
    //
    clientId: googleClientId,
  },
  rudderstack: {
    //
    writeKey: "2R6Lbc5jD3gxYXxLOPpjjn96Fd9",
    dataPlaneUrl: "https://martaanalhqwm.dataplane.rudderstack.com",
  },
};

export default env;
